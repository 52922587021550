<!--
  法人组织入驻 申请
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="法人组织入驻申请" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="form"
          :rules="rules"
          size="small"
          label-width="130px"
          label-position="left">
          <div class="module_box">
            <div class="module_box_header">
              <p>机构信息</p>
            </div>
            <div class="module_box_main">
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="机构名称"
                    prop="name">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.name"
                      placeholder="请填写组织全称">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="组织类别"
                    prop="orgCategory">
                    <el-select
                      style="width: 100%;"
                      v-model="form.orgCategory"
                      placeholder="请选择组织类别">
                      <el-option
                        v-for="item in staticData.zzlb"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="法定代表人"
                    prop="legalPerson">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.legalPerson"
                      placeholder="法人组织请填写法人姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="法人身份证号"
                    prop="legalPersonIdCard">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.legalPersonIdCard"
                      placeholder="请填写法人身份证号">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="机构网站"
                    prop="website">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.website"
                      placeholder="请输入机构网站">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                </el-col>
              </el-row>
              <!-- 新增行业领域 -->
              <el-form-item
                label="行业领域"
                prop="serviceTrade">
                <el-checkbox-group v-model="form.serviceTrade">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in industrySectorList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="产业特长"
                prop="industryExpertise">
                <el-checkbox-group v-model="form.industryExpertise">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in technicalList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="服务方向"
                prop="serviceDirection">
                <el-checkbox-group v-model="form.serviceDirection">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in staticData.fwfx"
                        :key="item"
                        :label="item">
                        {{ item }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="机构简介"
                prop="description">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="所从事的主营业务范围，团队介绍，所获得的成果和荣誉等"
                  v-model="form.description">
                </el-input>
              </el-form-item>
              <el-form-item
                label="机构服务案例"
                prop="serviceCase">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="典型机构服务案例1-2个"
                  v-model="form.serviceCase">
                </el-input>
              </el-form-item>
              <el-form-item
                label="统一社会信用代码"
                prop="creditCode">
                 <el-input maxlength="50" show-word-limit
                    style="width: 100%;"
                    v-model="form.creditCode"
                    placeholder="请输入统一社会信用代码">
                  </el-input>
              </el-form-item>
              <el-form-item
                label="营业执照"
                prop="businessLicense">
                <upload-files
                  :maxSize="4"
                  accept="image/png, image/jpg, image/jpeg"
                  :showTip="false"
                  listType="picture-card"
                  :fileList.sync="form.businessLicense">
                </upload-files>
              </el-form-item>
            </div>
          </div>
          <div class="module_box">
            <div class="module_box_header">
              <p>其他信息</p>
            </div>
            <div class="module_box_main">
              <el-form-item
                label="机构头像"
                prop="avatarUrl">
                <upload-files
                  :showFileList="false"
                  :maxSize="4"
                  accept="image/png, image/jpg, image/jpeg"
                  :readonly="true"
                  :showTip="false"
                  listType="custom"
                  customClassName="logourl_upload"
                  @successUpload="(file, res) => form.avatarUrl = res.data.url"
                  title="点击上传">
                  <el-image
                    v-if="form.avatarUrl"
                    style="width: 120px; height: 120px;"
                    :src="form.avatarUrl"
                    fit="cover">
                  </el-image>
                  <div v-else class="uploader-icon">
                    <i class="el-icon-plus"></i>
                  </div>
                </upload-files>
              </el-form-item>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="联系人"
                    prop="username">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.username"
                      placeholder="请填写联系人姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="联系人性别"
                    prop="gender">
                    <el-radio-group v-model="form.gender">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                 <el-form-item
                    label="联系电话"
                    prop="contactNumber">
                    <el-input maxlength="11" show-word-limit
                      style="width: 100%;"
                      v-model="form.contactNumber"
                      placeholder="请输入联系电话">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="联系邮箱"
                    prop="contactEmail">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.contactEmail"
                      placeholder="请输入联系邮箱">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="联系地址"
                    prop="contactAddressArray">
                    <el-cascader
                      style="width: 100%;"
                      placeholder="请选择联系地址"
                      v-model="form.contactAddressArray"
                      :options="staticData.cities">
                    </el-cascader>
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%; margin-top:10px;"
                      v-model="form.contactAddress"
                      placeholder="请输入详细地址">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>

        <div class="btn_box tw-mt-30px">
          <el-button
            style="margin-right: 20px;"
            type="primary"
            size="small"
            @click="submitForm"
            :loading="btnLoading"
            :disabled="!agree">
            提交申请
          </el-button>
          <el-checkbox v-model="agree">
            <p class="tw-text-14px tw-text-primary-text">已仔细阅读并同意
              <a :href="`${publicPath}诚信承诺书.pdf`" target="_blank">《诚信承诺书》</a>
            </p>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    /* // 身份类别选择其他时 进行验证
    const sflbOther = (rule, value, callback) => {
      if (value === '其他' && this.form.sflbOther === '') {
        callback(new Error('请注明其他身份类别'))
      } else {
        callback()
      }
    } */
    // 验证详细地址是否输入
    const contactAddress = (rule, value, callback) => {
      if (this.form.contactAddress === '') {
        callback(new Error('请输入详细地址'))
      } else {
        callback()
      }
    }
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      agree: false, // 是否同意条款
      /* form: {
        name: '1738554',
        orgCategory: '企业法人',
        legalPerson: '1738554赵',
        legalPersonIdCard: '420922199710136815',
        website: 'www.1738554.com',
        serviceTrade: [
          '65d3e998265ccda29b21ca7db882e61c',
          'c253a93fa36197e0a97479863114f326',
          '27d951e09829dcd4b68febfdc1a290da',
          'b336b1de4e00a5619405ce424c334ad1'
        ],
        industryExpertise: [
          '29056a0eee037b4dd9b1c59b1bab8aff',
          'd14cf608d101f2ccb256a2fee9091436',
          'd58a6dc233fd1d3d751ca7d3eba1690c'
        ],
        serviceDirection: [
          '科技金融',
          '科技咨询',
          '综合科技服务'
        ],
        description: '恐怖如斯',
        creditCode: '',
        businessLicense: [
          {
            uid: 1630637627627,
            name: '4CkeGvV0dQ6W7d1247d21bc9190b582ca383763522d9.png',
            id: 'bf9815328055eacdb4604da345b99862',
            createdAt: '2021-09-03 10:53:50',
            updatedAt: '2021-09-03 10:53:50',
            deletedAt: null,
            driver: 'local',
            originName: '4CkeGvV0dQ6W7d1247d21bc9190b582ca383763522d9.png',
            extName: 'png',
            contentType: 'image/png',
            size: '113',
            path: '2021/9/3/bf9815328055eacdb4604da345b99862.png',
            url: 'http://192.168.1.175:9919/media/bf9815328055eacdb4604da345b99862',
            downloadUrl: 'http://192.168.1.175:9919/media/bf9815328055eacdb4604da345b99862/download',
            status: 'success'
          }
        ],
        avatarUrl: 'http://192.168.1.175:9919/media/2725e7a2773a769959eef187562799cb',
        username: '1738554思',
        identityCategoryOther: '',
        contactNumber: '17371278554',
        contactEmail: '1738554@qq.com',
        contactAddress: '转角左边',
        gender: 1,
        serviceCase: '1、拯救过地球',
        contactAddressArray: [
          '110000',
          '110100',
          '110101'
        ]
      }, */
      form: {
        name: '', // 机构名称
        orgCategory: '', // 组织类别
        legalPerson: '', // 法定代表人
        legalPersonIdCard: '', // 法人代表身份证号码
        website: '', // 机构网站
        serviceTrade: [], // 行业领域
        industryExpertise: [], // 产业领域
        serviceDirection: [], // 服务方向
        description: '', // 机构简介
        creditCode: '',
        businessLicense: [], // 社会信用代码/营业执照

        // 用户信息
        avatarUrl: '', // 机构头像
        username: '', // 联系人
        // identityCategory: '', // 身份类别
        identityCategoryOther: '', // 身份类别，其他
        // idCard: '', // 用户身份证号
        // birthDate: '', // 出生年月
        // workUnit: '', // 工作单位
        // jobTitle: '', // 职务
        // highestEducation: '', // 最高学历
        contactNumber: '', // 联系电话
        contactEmail: '', // 联系邮箱
        contactAddressArray: [], // 联系地址，省市区选择器
        contactAddress: '', // 联系地址详细地址
        gender: '', // 性别
        serviceCase: '' // 机构服务案例
        // personalProfile: '', // 个人简介
        // positionalTitle: [] // 职称/所或资格认证
      },
      rules: {
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' }
        ],
        orgCategory: [
          { required: true, message: '请选择组织类别', trigger: 'change' }
        ],
        // legalPerson: [
        //   { required: true, message: '请填写法人姓名', trigger: 'blur' }
        // ],
        legalPersonIdCard: [
          // { required: true, message: '请填写法人身份证号', trigger: 'blur' }
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确身份证格式', trigger: 'blur' }
        ],
        // website: [
        //   { required: true, message: '请输入机构网站', trigger: 'blur' }
        // ],
        serviceTrade: [
          { required: true, message: '请选择行业领域', trigger: 'change' }
        ],
        industryExpertise: [
          { required: true, message: '请选择产业领域', trigger: 'change' }
        ],
        serviceDirection: [
          { required: true, message: '请选择服务方向', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入机构简介', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
        ],
        businessLicense: [
          // { required: true, message: '请上传统一社会信用代码/营业执照', trigger: 'blur' }
        ],

        avatarUrl: [
          { required: true, message: '请输入机构头像', trigger: 'blur' }
        ],
        contactEmail: [
          // { required: true, message: '请输入联系邮箱', trigger: 'blur' }
          { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确邮箱格式', trigger: 'blur' }
        ],
        contactNumber: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择联系人性别', trigger: 'change' }
        ],
        contactAddressArray: [
          { required: true, message: '请选择联系地址', trigger: 'change' },
          { validator: contactAddress, trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' }
        ],
        // idCard: [
        //   // { required: true, message: '请填写身份证号', trigger: 'blur' }
        //   { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确身份证格式', trigger: 'blur' }
        // ]
        // birthDate: [
        //   { required: true, message: '请选择出生年月', trigger: 'change' }
        // ],
        // positionalTitle: [
        //   { required: true, message: '请上传职称/所获资格认证', trigger: 'blur' }
        // ],
        // identityCategory: [
        //   // { required: true, message: '请选择身份类别', trigger: 'change' },
        //   { validator: sflbOther, trigger: 'blur' }
        // ]
        // workUnit: [
        //   { required: true, message: '请填写工作单位全称；如无，请填无', trigger: 'blur' }
        // ],
        // jobTitle: [
        //   { required: true, message: '请填写职务', trigger: 'blur' }
        // ],
        serviceCase: [
          { required: true, message: '请填写服务案例', trigger: 'blur' }
        ]
        // personalProfile: [
        //   { required: true, message: '请填写个人简介', trigger: 'blur' }
        // ],
        // highestEducation: [
        //   { required: true, message: '请填写最高学历', trigger: 'blur' }
        // ]
      }
    }
  },
  computed: {
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 开放目录
    publicPath () {
      return process.env.BASE_URL
    }
  },
  methods: {
    // 表单验证
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          // 申请服务机构
          this.corporateRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    corporateRegister () {
      let { serviceTrade, serviceDirection, industryExpertise, businessLicense, contactAddressArray } = this.form
      // 行业领域转为逗号分隔的字符串
      serviceTrade = serviceTrade ? serviceTrade.join(',') : ''
      // 服务方向转为逗号分隔字符串
      serviceDirection = serviceDirection ? serviceDirection.join(',') : ''
      // 产业特长转为逗号分隔字符串
      industryExpertise = industryExpertise ? industryExpertise.join(',') : ''
      // 社会信用代码证、营业执照
      businessLicense = businessLicense ? businessLicense.map(item => {
        return item.url
      }).join(',') : ''
      const [addressProvince, addressCity, addressCounty] = [...contactAddressArray]
      const formData = {
        ...this.form,
        serviceTrade,
        serviceDirection,
        industryExpertise,
        // positionalTitle,
        businessLicense,
        addressProvince,
        addressCity,
        addressCounty
      }

      this.btnLoading = true
      api.corporateRegister({
        ...formData
      }).then(res => {
        if (res.data.code === 0) {
          console.log('申请企业成功')
          this.$message.success('申请成功')
          this.$router.replace('/personal-info')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0px;
    .main_content {
      width: 1100px;
      margin: 0 auto;
      .module_box {
        .module_box_header {
          display: flex;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        .module_box_main {
          padding: 0 50px 0 20px;
        }
        &+.module_box {
          margin-top: 40px;
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        padding-left: 150px;

      }
      .logourl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader-icon {
          width: 120px;
          height: 120px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & i {
            font-size: 28px;
            color: #8c939d;
          }
        }
      }
      .codeurl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 220px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & > i {
            font-size: 40px;
            color: #9AA0A9;
          }
          & > p {
            width: 200px;
            font-size: 14px;
            line-height: 20px;
            color: #909399;
            margin-top: 15px;
          }
        }
      }
      .checkbox_wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 450px;
      }
    }
  }
}
</style>
